import React from "react";
import "./Plans.css";
import plansData from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";

export const Plans = () => {
  return (
    <div className="Plans" id="plans">
      {/* header */}

      <div className="blur plan-blur-l"></div>
      <div className="blur plan-blur-r"></div>

      <div className="header-p">
        <span className="stroke-text">Ready to start</span>
        <span>Your journey</span>
        <span className="stroke-text">now with us</span>
      </div>

      {/* Plans Cards */}

      <div className="Plans-cards">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>$ {plan.price}</span>
            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whiteTick} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div className="benefits">
              <span>See more benefits -></span>
            </div>
            <button className="btn">Join Now</button>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Plans;
