import React from "react";
import { useRef } from "react";
import "./join.css";
import emailjs from "@emailjs/browser";

export default function Join() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_epux7th', 'template_z0hwu0i', form.current, '5A5u412OEoAo6sPvn')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className="Join">
        <div className="left-j">
            <hr />
      <div>
        <span className="stroke-text">Ready to </span>
        <span>Level up</span>
      </div>
      <div>
        <span>Your body </span>
        <span className="stroke-text">with us?</span>
      </div>
      </div>

      <div className="right-j">
        <form ref={form} className="email" onSubmit={sendEmail}>  
        <input type="email" name="user_email" placeholder="Enter your email address" required />
        <button className="btn btn-j">Join Now</button>
        </form>
      </div>
    </div>
  );
}
