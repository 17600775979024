import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import CountUp from "react-countup";

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  return (
    <div className="hero">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        {/* Header */}
        <Header />

        {/* the-best-ad */}

        <div className="the-best-ad">
          <motion.div
            initial={{ left: "185px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>The best fitness club in town</span>
        </div>

        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>your </span>
          </div>
          <div>
            <span>ideal body</span>
          </div>
          <div>
            <span>
              Ideal body in here we will help you to shape and build your ideal
              body and live up your life to fullest
            </span>
          </div>
        </div>

        {/* figures */}

        <div className="figures">
          <div>
            <span>
              <CountUp
                start={100}
                end={140}
                duration={3}
                prefix="+"
              />
            </span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span><CountUp
                // start={100}
                end={975}
                duration={3}
                prefix="+"
              /></span>
            <span>members joined</span>
          </div>
          <div>
            <span><CountUp
                // start={100}
                end={50}
                duration={3}
                prefix="+"
              /></span>
            <span>fitness programs</span>
          </div>
        </div>

        {/* hero buttons */}

        <div className="hero-buttons">
          <div className="btn">Get Started</div>
          <div className="btn">Learn more</div>
        </div>
      </div>

      <div className="right-h">
        <button className="btn">Join Now</button>

        <motion.div
          className="heart-rate"
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
        >
          <img src={Heart} alt="heart" className="heart" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>

        {/* hero-image */}

        <img src={hero_image} alt="img" className="hero-image" />
        <motion.img
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          transition={transition}
          src={hero_image_back}
          alt="img-back"
          className="hero-image-back"
        />

        {/* calories */}

        <motion.div
          className="calories"
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
          transition={transition}
        >
          <img src={Calories} alt="calories" />
          <div>
            <span>Calories burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
export default Hero;
